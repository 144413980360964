
  import { defineComponent, ref, onMounted, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import axios, { AxiosError } from 'axios';
  import useAlert from "@/composables/Alert"
  import router from '@/router';
  import { handleAnonymizeName } from '@/composables/DataAnonymization';
  import {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
  } from '@/components';
  import { useLoaderStore } from "@/store/LoaderStore";
  import { useCompanyStore } from '@/store/CompanyStore';
  import { useFilterMainStore } from '@/store/FilterMainStore';
  import { campHandleCNPJ } from '@/composables/DataValidation';
  import  ModalCropImage from './Components/ModalCropImage.vue'
  import { Modal } from "bootstrap";
  
  interface IGroup{
    id: any,
    name : string,
    logo? : string,
    responsible : string,
    responsible_telephone : string,
    main_cnpj?: string;
  }
  
  function checkEmptyFields(obj): boolean {
    for (let key in obj) {
      if (obj[key] === "") {
        return true;
      }
    }
    return false;
  }
  
  export default defineComponent({
    name: "GroupPut",
    components: {
      CampHeader,
      CampFormHeader,
      CampFormRackSubmitBtn,
      ModalCropImage
    },
    setup() {
      const route = useRoute();
      const loaderStore = useLoaderStore();
      const { id } = route.params;
      const { showTimeAlert } = useAlert()
      const isRequiredField = ref(false)
      const companyStore = useCompanyStore()
      const filterMainStore = useFilterMainStore()
      const group = ref<IGroup>({
        id: '',
        name : '',
        logo : '',
        responsible : '',
        responsible_telephone : '',
        main_cnpj: '',
      });
      const imgCard = ref({
        imgBack:"" as any,
        imgNameLogo: "",
        imgLogo: "" as any,
        imgNameBack: ""
      });
      const proportion = ref()
      const modatlCroppedLogo = ref(true)
  
      // Functions
      async function onSubmitForm() {
        isRequiredField.value = false
        if(!group.value)
          return
        const requiredInputs: any = {
          name: group.value.name,
          responsible: group.value.responsible,
          responsible_telephone: group.value.responsible_telephone,
        }
        if(checkEmptyFields(requiredInputs))
          return isRequiredField.value = true
        loaderStore.open()
        try {
          if(group.value.main_cnpj) {
              const test_main_cnpj = <any>campHandleCNPJ(group.value.main_cnpj, "unformatted")
                group.value.main_cnpj = test_main_cnpj == false ? group.value.main_cnpj : test_main_cnpj
            } 
            group.value.logo = imgCard.value.imgBack
            const result = await axios.put(`/api/putGroup`, group.value)
            showTimeAlert("Atualizado com sucesso!")
            return router.push("/grupos")
        } catch (error) {
          if(error instanceof AxiosError)
            showTimeAlert(error.response?.data.message, "error")
          else
            showTimeAlert("Algo deu errado!", "error")
          loaderStore.close()
        }
      }
  
      function handleInitFilterModal() {
        if(!companyStore.getId) {
          filterMainStore.hideModal()
          filterMainStore.showModal()
        }
      }

      watch(() => group.value.main_cnpj, () => {
      if(group.value.main_cnpj) {
        campHandleCNPJ(group.value.main_cnpj)
      }
      })
  
      // Lifecycles
      onMounted(async () => {
        handleInitFilterModal()
        loaderStore.open()
        try {
          const result: any = await axios.get(`/api/getGroupView/${id}`)
          const { data } = result
          group.value = <IGroup>data.data
          const test_main_cnpj = typeof group.value.main_cnpj === "string" ? campHandleCNPJ(group.value.main_cnpj, "formatted") : ""
          group.value.main_cnpj = typeof test_main_cnpj === "string" ? test_main_cnpj : ""
        } catch (error) {
          if(error instanceof AxiosError) {
              showTimeAlert(error.response?.data.message, "error")
          } else {
            showTimeAlert("Algo deu errado!", "error")
          }
        } finally {
          loaderStore.close()
          if(group.value) {
            getImageCompany(group.value.logo, group.value.logo)
        }
        }
      })
  
      function getImgLogo(event) {
          modatlCroppedLogo.value = true
          proportion.value = 10/10
          const fileInput = event.target as HTMLInputElement;
          const file = fileInput.files?.[0];
          if (file) {
            imgCard.value.imgLogo = file
            imgCard.value.imgNameLogo = file.name
          }
          fileInput.value = "";
      }

      const auxModal:any = ref(null);
      const openModal = id => {
          const auxElement = document.querySelector(`#${id}`);
          auxModal.value = new Modal(auxElement);
          auxModal.value.show();
        };
      const auxModalBack:any = ref(null);
      const openModalBack = id => {
          const auxElement = document.querySelector(`#${id}`);
          auxModalBack.value = new Modal(auxElement);
          auxModalBack.value.show();
        };

      function setImageCroped(value) {
      if(!modatlCroppedLogo.value) {
        imgCard.value.imgBack = value.croppedImgBase64
        openModalBack("staticBackdropBack")
      }
      if(modatlCroppedLogo.value) {
        imgCard.value.imgLogo = value.croppedImgBase64
        openModal("staticBackdrop")
      }
    }

    function getImgBack(event) {
      modatlCroppedLogo.value = false
      proportion.value = 10/6
      const fileInput = event.target as HTMLInputElement;
      const file = fileInput.files?.[0];
      if (file) {
        imgCard.value.imgBack = file
        imgCard.value.imgNameBack= file.name
      }
      fileInput.value = "";
    }
    function getImageCompany(companyImgBack, companyImgBackName) {
      const splitString = (url) => {
        if(url) {
          const parts = url.split('/');
          return parts[parts.length - 1];
        }
      }
      imgCard.value.imgBack = companyImgBack
      imgCard.value.imgNameBack = splitString(companyImgBackName)
    }

    function handleRemoveImgLogo() {
      imgCard.value.imgNameBack = ""
      imgCard.value.imgBack = null
      group.value.logo = ""
    }

      return {
        isRequiredField,
        onSubmitForm,
        group,
        getImgLogo,
        imgCard,
        proportion,
        modatlCroppedLogo,
        setImageCroped,
        getImgBack,
        openModalBack,
        auxModalBack,
        // handleAnonymizeName,
        // group,
        handleRemoveImgLogo,
      }
    }
  })
  